<template>
  <CreateUpdateTemplate
    :routePreventDialog="routePreventDialog"
    :hasChanges="hasChanges"
    :customClass="'Create Staff create-form'"
    v-if="getPermission('engineer:create') || getPermission('engineer:update')"
  >
    <template v-slot:header-title>
      <div>
        <template v-if="updateStaff">
          <v-skeleton-loader
            class="custom-skeleton large-skeleton-title"
            v-if="pageLoading"
            type="text"
          >
          </v-skeleton-loader>
          <template v-else>
            <h1 class="form-title margin-auto">
              {{ getStaffName }}
              <SystemGenerateId
                v-if="barcode"
                :system-id="barcode"
              ></SystemGenerateId>
            </h1>
          </template>
        </template>
        <template v-else>
          <h1 class="form-title d-flex margin-auto">
            Create Technician
            <SystemGenerateId
              v-if="barcode"
              :system-id="barcode"
            ></SystemGenerateId>
          </h1>
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <div>
        <v-btn
          v-on:click="goBack"
          :disabled="formLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          depressed
        >
          Cancel
        </v-btn>
        <template v-if="updateStaff && pageLoading">
          <v-skeleton-loader
            class="custom-skeleton large-skeleton-action-button"
            type="button"
          >
          </v-skeleton-loader>
        </template>
        <v-btn
          v-else
          class="mx-2 custom-bold-button"
          depressed
          :disabled="!formValid || formLoading"
          :loading="formLoading"
          v-on:click="onSubmit"
          color="cyan white--text"
        >
          <template v-if="updateStaff">Update Technician</template>
          <template v-else>Save Technician</template>
        </v-btn>
      </div>
    </template>
    <template v-slot:body>
      <div class="" v-if="getPermission('engineer:create')">
        <v-form
          ref="engineerForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="onSubmit"
        >
          <v-row>
            <v-col cols="12" class="pt-0">
              <v-container fluid class="white-background pt-0">
                <v-tabs
                  v-if="false"
                  v-model="engineerTab"
                  background-color="transparent"
                  centered
                  color="cyan"
                  grow
                  fixed-tabs
                  active-clas="cyan"
                  class="custom-tab-transparent"
                  hide-slider
                >
                  <v-tab
                    class="font-size-16 font-weight-600 px-8"
                    :href="'#personal-info'"
                    :class="
                      engineerTab <= 'skilles-certificate' ? activeTabClass : ''
                    "
                  >
                    <v-icon color="grey darken-4" left
                      >mdi-account-circle</v-icon
                    >
                    Personal Info
                  </v-tab>
                  <v-tab
                    class="font-size-16 font-weight-600 px-8"
                    :href="'#emergency-contact'"
                    :class="
                      engineerTab <= 'skilles-certificate' &&
                      engineerTab >= 'personal-info'
                        ? activeTabClass
                        : ''
                    "
                  >
                    <v-icon color="grey darken-4" left>mdi-phone</v-icon>
                    Emergency Contact
                  </v-tab>
                  <v-tab
                    class="font-size-16 font-weight-600 px-8"
                    :href="'#engineer-address'"
                    :class="
                      engineerTab <= 'skilles-certificate' &&
                      engineerTab >= 'emergency-contact'
                        ? activeTabClass
                        : ''
                    "
                  >
                    <v-icon color="grey darken-4" left
                      >mdi-home-map-marker</v-icon
                    >
                    Address
                  </v-tab>
                  <v-tab
                    class="font-size-16 font-weight-600 px-8"
                    :href="'#skilles-certificate'"
                    v-if="uniqueId && certificate == 1"
                    :class="
                      engineerTab <= 'skilles-certificate' &&
                      engineerTab >= 'skilles-certificate'
                        ? activeTabClass
                        : ''
                    "
                  >
                    <v-icon color="grey darken-4" left
                      >mdi-certificate-outline</v-icon
                    >
                    Certificates
                  </v-tab>
                  <!-- <v-tab class="font-size-16 font-weight-600 px-8" :href="'#color'">
                <v-icon color="grey darken-4" left
                  >mdi-format-color-fill</v-icon
                >
                Color
              </v-tab> -->
                </v-tabs>
                <v-tabs
                  v-model="engineerTab"
                  background-color="transparent"
                  centered
                  color="cyan"
                  grow
                  fixed-tabs
                  class="custom-tab-transparent"
                  hide-slider
                >
                  <v-tab
                    class="font-size-16 font-weight-600 px-8"
                    :href="'#personal-info'"
                  >
                    <v-icon color="grey darken-4" left
                      >mdi-account-circle</v-icon
                    >
                    Personal Info
                  </v-tab>
                  <v-tab
                    class="font-size-16 font-weight-600 px-8"
                    :href="'#emergency-contact'"
                  >
                    <v-icon color="grey darken-4" left>mdi-phone</v-icon>
                    Emergency Contact
                  </v-tab>
                  <v-tab
                    class="font-size-16 font-weight-600 px-8"
                    :href="'#engineer-address'"
                  >
                    <v-icon color="grey darken-4" left
                      >mdi-home-map-marker</v-icon
                    >
                    Address
                  </v-tab>
                  <v-tab
                    class="font-size-16 font-weight-600 px-8"
                    :href="'#skilles-certificate'"
                    v-if="uniqueId && certificate == 1"
                  >
                    <v-icon color="grey darken-4" left
                      >mdi-certificate-outline</v-icon
                    >
                    Certificates
                  </v-tab>
                  <!-- <v-tab class="font-size-16 font-weight-600 px-8" :href="'#color'">
                <v-icon color="grey darken-4" left
                  >mdi-format-color-fill</v-icon
                >
                Color
              </v-tab> -->
                </v-tabs>
                <perfect-scrollbar
                  :options="{ suppressScrollX: true }"
                  class="scroll custom-box-top-inner-shadow engineer-create-height"
                  style="max-height: 90vh; position: relative"
                >
                  <v-tabs-items
                    v-model="engineerTab"
                    class="custom-min-height-60vh"
                  >
                    <v-tab-item :value="'personal-info'">
                      <PersonalInfo
                        :pageLoading="pageLoading"
                        :updateStaff="updateStaff"
                        :engineer="engineerArr"
                        v-on:barcode="engineerId"
                      ></PersonalInfo>
                    </v-tab-item>
                    <v-tab-item :value="'emergency-contact'">
                      <EmergencyContact
                        :pageLoading="pageLoading"
                        :updateStaff="updateStaff"
                        :engineer="engineerArr"
                      ></EmergencyContact>
                    </v-tab-item>
                    <v-tab-item :value="'engineer-address'">
                      <StaffAddress
                        :pageLoading="pageLoading"
                        :updateStaff="updateStaff"
                        :engineer="engineerArr"
                      ></StaffAddress>
                    </v-tab-item>
                    <v-tab-item eager :value="'skilles-certificate'">
                      <SkillesCertificate
                        ref="groupCertificatesRef"
                        :pageLoading="pageLoading"
                        :updateStaff="updateStaff"
                        :engineer="engineerArr"
                      ></SkillesCertificate>
                    </v-tab-item>
                    <v-tab-item :value="'color'">
                      <ColorTemplate
                        :pageLoading="pageLoading"
                        :updateStaff="updateStaff"
                        :engineer="engineerArr"
                      ></ColorTemplate>
                    </v-tab-item>
                  </v-tabs-items>
                </perfect-scrollbar>
              </v-container>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PersonalInfo from "@/view/pages/engineer/create-or-update/Personal-Info";
import EmergencyContact from "@/view/pages/engineer/create-or-update/Emergency-Contact";
import StaffAddress from "@/view/pages/engineer/create-or-update/Engineer-Address";
import SkillesCertificate from "@/view/pages/engineer/create-or-update/Skills-Certificate";
import ColorTemplate from "@/view/pages/engineer/create-or-update/Color";
import StaffMixin from "@/core/lib/engineer/engineer.mixin";
import SystemGenerateId from "@/view/pages/partials/SystemGenerateId.vue";
import { GET } from "@/core/services/store/request.module";
export default {
  mixins: [CommonMixin, StaffMixin],
  name: "manage-engineer-create",
  title: "Create Staff",
  data() {
    return {
      updateStaff: false,
      barcode: null,
      uniqueId: Number(new Date()),
      certificate: null,
    };
  },
  components: {
    PersonalInfo,
    EmergencyContact,
    StaffAddress,
    SkillesCertificate,
    ColorTemplate,
    SystemGenerateId,
    CreateUpdateTemplate,
  },
  watch: {
    engineer(param) {
      if (param > 0) {
        this.getStaffDetails();
      }
    },
  },
  methods: {
    engineerId(param) {
      this.barcode = param;
    },

    getSettings() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "engineer/settings",
        })
        .then(({ data }) => {
          this.certificate = data;
          this.uniqueId = Number(new Date());
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getStaffDetails() {
      const _this = this;
      if (_this.engineer) {
        _this
          .getEngineer()
          .then((response) => {
            _this.engineerArr = response;
            _this.barcode = _this.engineerArr.barcode;
            _this.data = _this.engineerArr;
            _this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Technician", route: "engineer" },
              { title: "Update" },
              // { title: response.barcode },
            ]);
          })
          .catch((error) => {
            _this.logError(error);
            _this.$router.go(-1);
          })
          .finally(() => {
            _this.pageLoading = false;
          });
      }
    },
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Technician", route: "engineer" },
      { title: "Create" },
    ]);

    _this.getStaffDetails();
    _this.getSettings();
  },
  computed: {
    getStaffName() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.engineerArr) === false) {
        return _this.engineerArr.full_name;
      }
      return null;
    },
  },
  created() {
    const _this = this;
    if (
      _this.$route.name == "admin.engineer.update" ||
      _this.$route.name == "engineer.engineer.update"
    ) {
      _this.updateStaff = true;
      _this.pageLoading = true;
      _this.engineer = _this.$route.params.id;
      if (!_this.engineer || _this.engineer <= 0) {
        _this.$router.go(-1);
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.engineerCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>
<style scoped>
.v-text-field--filled > .v-input__control > .v-input__slot {
  min-height: 100%;
}
.v-input input {
  max-height: 30px;
}

/* tabs css  */

.custom-tab-transparent.v-tabs--fixed-tabs > .v-tabs-bar .v-tab.v-tab--active {
  background-color: #00bcd4 !important;
  color: #fff !important;
  overflow: hidden;
}
.custom-tab-transparent.v-tabs--fixed-tabs
  > .v-tabs-bar
  .v-tab.v-tab--active
  .grey--text.text--darken-4 {
  color: #fff !important;
  caret-color: #fff !important;
}

/* .custom-tab-transparent.v-tabs {
  height: 100% !important;
} */
/* 
.custom-tab-transparent.v-tabs .v-tab {
  border-radius: 0 !important;
  height: auto !important;
} */

.custom-tab-transparent.v-tabs .v-tab {
  background: #00bcd429;
  width: 25%;
  clip-path: polygon(
    0% 0%,
    calc(100% - 30px) 0%,
    100% 50%,
    calc(100% - 50px) 150%,
    0% 100%,
    calc(0% + 20px) 50%
  );
}

.custom-tab-transparent.v-tabs .v-tab:first-child {
  clip-path: polygon(
    0% 0%,
    calc(100% - 30px) 0%,
    100% 50%,
    calc(100% - 50px) 150%,
    0% 100%,
    calc(0% + 0px) 50%
  );
}

.custom-tab-transparent.v-tabs .v-tab.v-tab:last-child {
  clip-path: polygon(
    0% 0%,
    calc(100% + -0px) 0%,
    100% 100%,
    calc(100% - 40px) 150%,
    0% 100%,
    calc(0% + 20px) 50%
  );
}
</style>
